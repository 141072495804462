.shimmer {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    display: inline-block;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: inherit;
        animation: shimmer 1.5s infinite linear;
    }

    @keyframes shimmer {
        0% {
            background-position: -1000px 0;
        }

        100% {
            background-position: 1000px 0;
        }
    }
}

.table-row-shimmer {
    display: table-row;
  }
  
  .table-cell-shimmer {
    display: table-cell;
    padding: 10px;
    height: 20px;
    border-bottom: 1px solid #ddd;
  }