.claim-information-wrapper {
    .card {
        .divider-name {
            color: #000;
            left: 10px;
            top: -12px;
            z-index: 2;
            font-size: 12px;
            padding: 3px 5px;
            position: absolute;
            background-color: #ffffff;
        }
    }
}
.activity-log-timeline .activity-group {
    .activity-item::before {
        content: "";
        position: absolute;
        top: 2px;
        left: -6px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #c8cbd3;
        color: rgba(255, 255, 255, 0.75);
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: -54px;
        font-family: "remixicon";
    }
    .activity-item {
        padding-left: 15px;
        position: relative;
    }
}
.media-connection-bttn {
    .form-check {
        padding: 0;
        position: relative;
        overflow: hidden;

        label {
            cursor: pointer;
            padding: 4px 10px;
            border-radius: 0.25rem;
            border: 1px solid #ccc;
        }

        input {
            margin: 0;
            padding: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            border-radius: 0;
            cursor: pointer;

            &:checked+label {
                color: #506fd9;
                border-color: #506fd9;
            }
        }

    }
}

.decision-tree-form {
    padding: 15px;
    overflow-y: auto;
    height: calc(100vh - 90px);
}

// SMALL DESKTOP VIEW > 1024 DEVICE
@media screen and (max-width: 1024px) {
    body {
        .case-disposition-modal {
            .card.case-details-card {
                height: calc(100vh - 150px);
            }
        }
    }
}

// MOBILE VIEW > 480 DEVICE
@media screen and (max-width: 480px) {
    body {
        .case-disposition-modal {
            .offcanvas-header {
                display: block;

                button {
                    &.btn-default {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                    }
                }

                .park-case-wrapper {
                    justify-content: end;
                }
            }

            .offcanvas-body {
                .card {
                    &.case-details-card {
                        height: calc(100vh - 208px);
                    }
                }

                .document-upload-table {
                    table {
                        thead {
                            tr {
                                th {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }

            .offcanvas-footer {
                padding: 10px;
            }
        }
    }
}