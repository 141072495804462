body {
  .otp-form-field {
    div {
      display: block !important;
      input {
        width: 3em !important;
        text-align: center;
        height: 2.5rem !important;
      }
    }
  }
  // background-color: #fafafa !important;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .overflow-y {
    overflow-y: scroll;
  }

  .nodes-flow {
    height: calc(100vh - 72px);
  }

  .react-flow {
    height: calc(100vh - 130px) !important;

    // .react-flow__attribution {
    //   display: none;
    // }
  }

  .flow-container {
    width: 100%;
    height: 100vh;
  }

  .spinner-loader-sidenav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .badge-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;

    &.bg-appeals-bg {
      background-color: #5d6a87 !important;
    }

    &.bg-AR-follow-Up-bg {
      background-color: #2a6694 !important;
    }

    &.bg-auth-denial-bg {
      background-color: #942a51 !important;
    }
  }

  .badge {
    white-space: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin: 0;
    padding: 0;
  }

  a {
    color: #506fd9;
    cursor: pointer !important;

    &:hover {
      color: #152b76;
    }
  }

  .z-index1 {
    z-index: 1 !important;
  }

  .z-index2 {
    z-index: 2 !important;
  }

  .z-index3 {
    z-index: 3 !important;
  }

  .z-index4 {
    z-index: 4 !important;
  }

  .overflow-y {
    overflow-y: scroll;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right !important;
  }

  img {
    object-fit: cover;
  }

  .position-relative {
    position: relative;
  }

  .right-10 {
    right: 10px;
  }

  .right-20 {
    right: 20px;
  }

  .bg-pink-4f0 {
    background-color: #fbf4f0 !important;
  }

  table thead tr th,
  table tbody tr td {
    vertical-align: middle;
  }

  .card-one.sub-page-card {
    height: calc(100vh - 190px);
  }
  .ms-12 {
    margin-left: 50px;
  }
  // BADGE CLASSES
  .badge {
    &.ligth-gray {
      --bs-bg-opacity: 1;
      color: #000000;
      background-color: #eaeaea !important;
    }
  }

  // . BTN OUTLINE SECONDARY
  .btn-outline-secondary {
    &:focus {
      color: #6e7985;
    }

    &:active {
      color: #fff !important;
    }
  }

  // TWO THREE LINE ELLIPSIS
  .two-line-ellipsis,
  .three-line-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .two-line-ellipsis {
    -webkit-line-clamp: 2;
  }

  .three-line-ellipsis {
    -webkit-line-clamp: 3;
  }

  // TABLE RESPONSIVE
  .table-responsive {
    overflow: inherit;
  }

  // ICONS BUTTON CSS
  .btn-icon {
    width: 30px;
    height: 30px;
  }

  // SCROLLBAR CONTAINER
  .scrollbar-container {
    .nav-pills {
      .nav-item {
        .nav-link {
          color: #000000;
          display: flex;
          align-items: center;

          i {
            margin-right: 12px;
            line-height: 1;
            font-size: 20px;
            width: 18px;
            opacity: 0.85;
          }

          &.active {
            background-color: #f3f3f3;
          }
        }
      }
    }
  }

  // MIAN FOOTER
  .main-footer {
    position: fixed;
    padding: 10px 30px;
    left: 250px;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: #fbfcfe;
  }

  // LOGIN MESSAGE
  .login-msg {
    top: 20px;
    left: 20px;
    position: absolute;
  }

  // OFF CANVAS CSS BEGIN
  .offcanvas-header {
    border-bottom: 0;
    z-index: 10;
    box-shadow: 0px 1px 5px 1px rgba(64, 60, 67, 0.16);
  }

  .offcanvas.offcanvas-end {
    &.off-canvas-custom-width {
      width: 45%;

      .offcanvas-header {
        height: 72px;
        padding: 1rem 1.5rem;
      }

      .offcanvas-body {
        padding: 1rem 1.5rem;
      }
    }

    .custom-offcanvas-header {
      .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }

    .offcanvas-footer {
      display: flex;
      align-items: center;
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      height: 70px;
      padding: 1rem 1.5rem;
      justify-content: space-between;
      border-top: 1px solid rgb(204, 210, 218);
      box-shadow: 0 1px 1px rgba(33, 40, 48, 0.01),
        0px -1px 4px rgba(33, 40, 48, 0.01), 0 16px 16px rgba(33, 40, 48, 0.01);
    }
  }

  // TBALE FILTER
  .table-filter {
    .filter-bttn {
      position: relative;

      .filter-pill {
        position: absolute;
        top: -10px;
        right: -8px;
        font-size: 18px;
        line-height: normal;
      }
    }
  }

  // COMPLETED BUCKET STATUS
  .completd-bucket-status {
    .progress-bar {
      background-color: #0cb785;
    }
  }

  // INCOMPLETED BUCKET STATUS
  .incompletd-bucket-status {
    .progress-bar {
      background-color: #dc3545;
    }
  }

  // COMMON CUSTOM TABS
  .nav-tabs.common-custom-tabs {
    margin-bottom: 20px;

    .nav-item {
      .nav-link {
        color: #343434;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        font-weight: 600;
        position: relative;
        text-transform: capitalize;
        border-bottom: 1px solid transparent;
        background-color: transparent;

        &:hover,
        &.active {
          color: #4059ae;
          background-color: transparent;
          border-bottom: 2px solid #4059ae;
        }
      }
    }
  }

  textarea,
  select,
  input {
    &:focus {
      border-color: #212830 !important;
    }
  }

  .offcanvas-backdrop {
    z-index: 1045;
  }

  // QUERY BUILDER CSS
  .ruleGroup {
    background: rgb(228 228 228 / 20%);

    .ruleGroup-addRule {
      background-color: #212830;
      border: 1px solid #212830;
      color: #fff;
      border-radius: 3px;
      padding: 4px 8px;
    }

    .ruleGroup-addGroup {
      background-color: #506fd9;
      border: 1px solid #506fd9;
      color: #fff;
      border-radius: 3px;
      padding: 4px 8px;
    }

    .ruleGroup-remove,
    .rule-remove {
      color: #dc3545;
      padding: 1px 8px;
      font-size: 18px;
      border-radius: 3px;
      border: 1px solid #dc3545;
      background-color: transparent;

      &:hover {
        color: #ffffff;
        background-color: #dc3545;
      }
    }

    .rule-cloneRule,
    .rule-lock,
    .ruleGroup-cloneGroup,
    .ruleGroup-lock {
      padding: 4px 8px;
      border-radius: 3px;
      border: 1px solid #212830;

      &:hover {
        color: #ffffff;
        background-color: #212830;
      }
    }

    .rule-cloneRule,
    .ruleGroup-cloneGroup {
      color: #0dcaf0;
      border-color: #0dcaf0;

      &:hover {
        color: #ffffff;
        background-color: #0dcaf0;
      }
    }

    select,
    input {
      border-radius: 0.25rem;
      padding: 0.406rem 0.75rem;
      background-color: #ffffff;
      border: 1px solid #ccd2da;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:disabled {
        opacity: 0.6;
      }
    }
  }

  // COMMON NAV TABS
  .common-nav-tabs {
    border-bottom: 1px solid #e2e5ec;

    .nav-item {
      .nav-link {
        color: #343434;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        position: relative;
        text-transform: capitalize;
        border-bottom: 1px solid transparent;
        background-color: transparent;

        &.active {
          color: #506fd9;
          border-bottom: 2px solid #506fd9;
          background-color: #f3f3f3;
        }
      }
    }
  }

  // UPLOAD INVENTORY
  .upload-inventory {
    label {
      color: #fff;
      width: 100%;
      position: relative;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #506fd9;
      padding: 10px 0;
      border-radius: 4px;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  // EMPTY STATE
  .empty-state {
    width: 100%;
    display: flex;
    text-align: center;
    margin-top: 2rem;
    justify-content: center;

    button {
      margin: 0 auto;
    }
  }

  .card {
    &.case-details-card {
      height: calc(100vh - 138px);
    }
  }

  // CUSTOME SCROLL BAR
  .custom-scroll {
    &::-webkit-scrollbar {
      height: 5px;
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background: #ddd;
    }
  }

  .middle-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  // CASE DETAILS CUSTOM CARD STYLE
  // .case-details-card {
  //     .case-details-pb {
  //         padding: 15px;
  //         position: relative;
  //         z-index: 10;
  //         overflow-y: auto;
  //         height: calc(100vh - 27px);
  //         padding-bottom: 40px;
  //         position: relative;
  //         z-index: 90;
  //     }

  //     .decision-tree-save-bttn {
  //         position: absolute;
  //         bottom: 0;
  //         left: 0;
  //         right: 0;
  //         padding: 10px 15px;
  //         text-align: right;
  //         z-index: 2;
  //         background-color: #ffffff;
  //     }
  // }

  .decision-tree-save-bttn {
    text-align: right;
  }

  // LOGIN CONTENT
  .login-content-logo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;

    img {
      filter: brightness(0) invert(1);
    }
  }

  .login-content {
    position: absolute;
    left: 0;
    top: 50%;
    padding: 20px;
    width: 70%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);

    h1 {
      font-size: 3.2vw;
    }

    h2 {
      font-size: 1.2vw;
    }
  }

  // USER PROFILE
  .user-profile {
    color: #424242;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #c3c3c3;
  }

  .main-app.tl-dashboard-main-app {
    height: calc(100vh - 72px);
  }

  .react-flow-footer {
    padding: 10px 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: right;
    background-color: #fff;
    z-index: 2;
    border-top: 1px solid #dddddd;
  }
}

@media screen and (max-width: 1200px) {
  body {
    .case-details-card {
      height: calc(100vh - 100px);
    }
  }
}
